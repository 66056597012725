<template>
  <v-list-item @click.stop="onShow">
    <span v-if="!editMode">
      {{ item.text }}
    </span>
    <v-dialog
      v-model="dialog"
      max-width="500px"
      persistent
    >
      <v-card>
        <!-- Subheading -->
        <v-card-title class="subheading-title">
          Trunk Selection
        </v-card-title>

        <!-- Search Bar -->
        <v-card-text class="pb-1">
          <v-container fluid class="search">
            <v-row no-gutters>
              <v-col cols="8">
                <v-text-field
                  v-model="search"
                  label="Search Trunks"
                  dense
                  clearable
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- Scrollable List of Trunks -->
        <v-card-text class="pb-0 scrollable-content">
          <v-container fluid>
            <v-row no-gutters>
              <v-col
                v-for="trunk in filteredTrunks"
                :key="trunk.TrunkID"
                cols="12"
              >
                <v-checkbox
                  :value="trunk.TrunkID"
                  :label="trunk.Description"
                  v-model="id"
                  dense
                  hide-details
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- Sticky Footer with Buttons -->
        <v-card-actions class="sticky-footer">
          <v-spacer />
          <v-btn
            text
            @click="onCancel"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="!id.length"
            color="primary"
            text
            @click="onSubmit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
import axios from 'axios';
import { sortBy } from 'lodash';
import { mapGetters } from 'vuex';
import config from '@/config.js';

export default {
  name: 'QueryBuilderTrunkId',
  props: {
    item: Object,
    query: Object,
    editMode: Boolean,
  },
  data() {
    return {
      dialog: false,
      id: [],
      trunks: [],
      search: '', // Search term for filtering trunks
    };
  },
  computed: {
    ...mapGetters(['selectedAccountId']),
    filteredTrunks() {
      const searchTerm = this.search.toLowerCase();
      return this.trunks.filter((trunk) => trunk.Description.toLowerCase().includes(searchTerm));
    },
  },
  mounted() {
    this.loadTrunks();
    if (this.editMode && this.query && this.query.query && this.query.query.bool && this.query.query.bool.filter[0].terms.trunkid) {
      this.id = this.query.query.bool.filter[0].terms.trunkid;
    }
  },
  methods: {
    loadTrunks() {
      axios
        .get(`${config.BTL_API_TENANT_URL}/accounts/${this.selectedAccountId}/trunks`)
        .then((response) => {
          this.trunks = sortBy(response.data, (trunk) => trunk.Description.toLowerCase());
        })
        .catch((error) => {
          console.error('ERROR', error.response);
        });
    },
    onShow() {
      this.dialog = !this.dialog;
    },
    onCancel() {
      this.dialog = false;
    },
    onSubmit() {
      const item = { ...this.item };
      item.type = 'trunkId';

      const query = {
        query: {
          bool: {
            filter: [
              {
                terms: {
                  trunkid: this.id,
                },
              },
            ],
          },
        },
      };

      const trunks = this.trunks.filter((t) => this.id.includes(t.TrunkID));
      this.$emit('submit', {
        item,
        query,
        text: trunks.map((trunk) => trunk.Description).join(', '),
      });
      this.dialog = false;
      this.id = [];
    },
  },
};
</script>

<style scoped>
.scrollable-content {
  max-height: 300px;
  overflow-y: auto;
}

.sticky-footer {
  position: sticky;
  bottom: 0;
  background: #fff;
  padding-bottom: 16px;
}

.subheading-title {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

/* Adjusted padding below search bar */
.search {
  padding-bottom: 1px !important;
}
</style>
