import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import moment from 'moment-timezone';

// eslint-disable-next-line import/no-cycle
import { getAuthenticationContext } from '../auth/AuthService';
import config from '../config';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    authentication: null,
    userPreferences: null,
    searchQuery: '',
    timeZones: [],
    selectedAccountId: localStorage.getItem('selected_account'),
  },
  getters: {
    timeZone: (state) => {
      if (!state.userPreferences) {
        return 'Europe/London';
      }
      return state.userPreferences.timeZone;
    },
    searchQuery: (state) => state.searchQuery || '',
    timeZones: (state) => state.timeZones || [],
    selectedAccountId: (state) => state.selectedAccountId,
    authentication: (state) => state.authentication,
  },
  mutations: {
    setAuthentication: (state, authentication) => {
      state.authentication = authentication || {};
    },
    setTimeZones: (state, timeZones) => {
      state.timeZones = timeZones || [];
    },
    setUserPreferences: (state, userPreferences) => {
      state.userPreferences = userPreferences;
    },
    setSearchQuery: (state, searchQuery) => {
      state.searchQuery = searchQuery;
    },
    setSelectedAccountId: (state, selectedAccountId) => {
      state.selectedAccountId = selectedAccountId;
    },
    addWidget: (state, widget) => {
      state.userPreferences.dashboard.widgets.push(widget);
    },
  },
  actions: {
    loadUserPreferences: ({ state, commit }) => axios.get(`${config.bizvu_user_url}/account/${state.authentication.userAccountId}/user/${state.authentication.userId}/preferences`)
      .then((response) => {
        commit('setUserPreferences', response.data);
      }),
    saveUserPreferences: ({ state, commit }, userPreferences) => axios.patch(`${config.bizvu_user_url}/account/${state.authentication.userAccountId}/user/${state.authentication.userId}/preferences`, userPreferences)
      .then(() => {
        commit('setUserPreferences', userPreferences);
      }),
    handleLogin: ({ commit, dispatch }, authenticationContext) => {
      commit('setAuthentication', authenticationContext);
      const savedAccountId = localStorage.getItem('selected_account');
      dispatch('updateAccountId', savedAccountId || authenticationContext.userAccountId);
    },
    loadAuthentication: ({ state, dispatch }) => {
      if (!state.authentication || !Object.values(state.authentication).length) { // means we're logged in but the page is reloaded and store emptied
        const authContext = getAuthenticationContext();
        if (authContext) {
          dispatch('handleLogin', authContext);
        }
      }
    },
    loadTimezones: ({ commit }) => {
      const timeZones = moment.tz.names().map((timezone) => ({
        text: `(GMT ${moment().tz(timezone).format('Z')}) ${timezone.split('/').pop().replace('_', ' ')} ${timezone}`,
        name: timezone.split('/').pop().replace('_', ' '),
        offset: `GMT${moment().tz(timezone).format('Z')}`,
        value: timezone,
      }));

      commit('setTimeZones', timeZones);
    },
    updateAccountId: ({ commit }, accountId) => {
      localStorage.setItem('selected_account', accountId);
      commit('setSelectedAccountId', accountId);
    },
  },
});

export default store;
