<template>
  <v-container
    v-resize="onResize"
    class="pr-6"
    fluid
  >
    <div>
      <v-toolbar>
        <div class="search-wrapper">
          <div>
            <v-icon class="mr-2">
              search
            </v-icon>
          </div>
          <div class="search-input">
            <div>
              <v-chip
                v-for="(chip, index) in chips"
                :key="index"
                class="mr-1 mb-1"
                label
                close
                @click:close="onClose(chip)"
              >
                {{ chip.item.text }}{{ chip.text ? ": " + chip.text : "" }}
                <v-btn
                  v-if="chip.item.editable"
                  small
                  icon
                  @click="editFilter(chip)"
                >
                  <v-icon small>
                    edit
                  </v-icon>
                </v-btn>
              </v-chip>
            </div>
            <v-menu
          v-model="showList"
          max-height="50vh"
        >
          <!-- Activator for the filter selection dropdown -->
          <template #activator="{ on }">
            <div
              class="search pt-1 ml-2 mb-1"
              v-on="on"
            >
              <span
                class="subtitle-1 grey--text"
              >Click here to add a filter...</span>
            </div>
          </template>
          <!-- Filter selection dropdown -->
          <v-list
            v-if="showList"
            dense
          >
            <v-subheader>Select a filter:</v-subheader>
            <component
              :is="item.category"
              v-for="(item, index) in categories"
              :key="index"
              :item="item"
              @submit="onSubmit"
              @click.stop
            />
          </v-list>
        </v-menu>
          </div>
          <div>
            <v-tooltip
              v-if="!editingScheduleQuery"
              bottom
            >
              <!-- <template #activator="{ on }">
                <v-btn
                  icon
                  :disabled="!chips.length"
                  @click="executeQuery"
                  v-on="on"
                >
                  <v-icon>forward</v-icon>
                </v-btn>
              </template>
              <span>Submit query</span> -->
            </v-tooltip>
            <v-tooltip
              v-if="editingScheduleQuery"
              bottom
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  @click="updateSchedule"
                  v-on="on"
                >
                  <v-icon>save</v-icon>
                </v-btn>
              </template>
              <span>Save query</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  icon
                  @click="onClear"
                  v-on="on"
                >
                  <v-icon>clear</v-icon>
                </v-btn>
              </template>
              <span>Clear all filters</span>
            </v-tooltip>
          </div>
        </div>
      </v-toolbar>
      <div class="container-fluid px-0 pt-4">
        <div class="row">
  <!-- Histogram Column -->
  <div :class="['col-lg-8', { 'd-none': !showCanvas }]">
    <div class="canvas-wrapper">
      <canvas id="myChart" width="100" height="45"></canvas>
    </div>
  </div>

  <!-- Call Statistics Column -->
  <div :class="['col-lg-4', { 'col-lg-12': !showCanvas }]">
    <div class="card">
      <div class="card-body">
        <div class="mb-4">
          <div class="stats-header">
            <h5 class="card-title">Call Statistics</h5>
            <div class="toggle-buttons-wrapper">
              <!-- Dropdown for Group By and Histogram Toggle -->
              <div class="d-flex justify-content-between align-items-center group-toggle" style="width: 100%;">
                <div class="toggle-label">{{ showCanvas ? 'Hide Histogram' : 'Show Histogram' }}</div>
                <v-switch id="toggle-canvas" v-model="showCanvas" @change="toggleCanvasVisibility"></v-switch>
              </div>
            </div>
          </div>
          <div :class="['highlighted-section-wrapper', { 'highlighted-section-wrapper-row': !showCanvas }]">
            <div class="highlighted-section">
              <div class="d-flex align-items-center justify-content-between subheading-section">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" v-model="showIncoming" @change="updateChart">
                </div>
                <h6 class="highlighted-text mb-0">Incoming Calls</h6>
              </div>
              <p class="highlighted-text">Total calls: {{ totalIncomingCalls }}</p>
            </div>

            <div class="highlighted-section">
              <div class="d-flex align-items-center justify-content-between subheading-section">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" v-model="showOutgoing" @change="updateChart">
                </div>
                <h6 class="highlighted-text mb-0">Outgoing Calls</h6>
              </div>
              <p class="highlighted-text">Total calls: {{ totalOutgoingCalls }}</p>
            </div>
            <div class="highlighted-section">
            <div class="d-flex align-items-center justify-content-between subheading-section">
              <h6 class="highlighted-text mb-0">Status</h6>
            </div>
            <p class="highlighted-text">Successful: {{ successfulCalls }} ({{ totalCalls > 0 ? ((successfulCalls / totalCalls * 100).toFixed(2)) : '0.00' }}%)</p>
            <p class="highlighted-text">Abandoned: {{ abandonedCalls }} ({{ totalCalls > 0 ? ((abandonedCalls / totalCalls * 100).toFixed(2)) : '0.00' }}%)</p>
            <p class="highlighted-text">Failover: {{ otherStatusesCalls.totalOtherStatuses }} ({{ otherStatusesCalls.percentageOtherStatuses }}%)</p>
          </div>
            <div class="highlighted-section">
              <h6 class="highlighted-text mb-0">Total Call Time</h6>
              <p class="highlighted-text">{{ totalCallTime }} minutes</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  </div>
      <v-alert
        v-if="hitsExceedResultsLimit"
        class="my-0"
        type="info"
        dense
        text
      >
        Found <b>{{ totalHits }}</b> calls. Latest
        {{ analytics_query_size }} loaded, use download to view all data.
      </v-alert>

      <v-container
        v-if="!editingScheduleQuery"
        ref="table"
        fluid
        class="pl-0 pr-0"
        style="height: calc(100vh - 320px)"
      >
        <v-row
          no-gutters
          style="flex-wrap: nowrap"
        >
          <v-col class="table">
            <queryTable
              :display-headers="displayHeaders"
              :records="records"
              :loading="loading"
              :table-height="tableHeight"
              :items-per-page="100"
              :items-per-page-options="[100, 250, 500]"
              :no-data-text="noDataText"
            />
          </v-col>
          <v-col style="max-width: 36px; min-width: 36px; margin-top: 5em;">
            <v-row justify="center">
              <v-btn
                icon
                title="Toggle Fields"
                @click.stop="toggleFields"
              >
                <v-icon>text_fields</v-icon>
              </v-btn>
            </v-row>
            <v-row justify="center">
              <v-btn
                icon
                title="Order Fields"
                @click.stop="showFieldSorter = true"
              >
                <v-icon>swap_horiz</v-icon>
              </v-btn>
            </v-row>
            <v-row justify="center">
              <v-btn
                icon
                :disabled="!Object.keys(query).length || isSixMonthSummaryActive"
                text
                title="Report Options"
                @click.stop="nameQueryDialog"
              >
                <v-icon>add_box</v-icon>
              </v-btn>
            </v-row>
            <v-row justify="center">
              <query-downloader
                :report-name="reportDownloadName"
                :query="query.body"
                :headers="
                  displayHeaders.map((header) => ({
                    text: header.text.text,
                    value: header.value,
                  }))
                "
              />
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-dialog
        v-model="showFieldsDialog"
        max-width="800px"
        persistent
      >
        <v-card>
          <v-toolbar color="primary">
            <v-toolbar-title class="white--text">
              Toggle Fields
            </v-toolbar-title>
            <v-spacer />
            <v-btn
              class="mr-2"
              color="secondary"
              @click="selectAll"
            >
              Select All
            </v-btn>
            <v-btn
              color="secondary"
              @click="selectNone"
            >
              Select None
            </v-btn>
          </v-toolbar>
          <v-card-text class="pb-0">
            <v-container fluid>
              <v-tabs v-model="fieldTab">
                <v-tab>
                  <v-icon left>
                    mdi-account
                  </v-icon>
                  Main Fields
                </v-tab>
                <v-tab>
                  <v-icon left>
                    mdi-lock
                  </v-icon>
                  Dynamically Routed Fields
                </v-tab>

                <v-tab-item>
                  <v-row
                    dense
                    justify="center"
                    align="center"
                  >
                    <v-col
                      v-for="(value, key) in headers"
                      :key="value.text"
                      sm="4"
                      md="3"
                      lg="3"
                    >
                      <v-checkbox
                        v-model="selectedFields"
                        :label="value.text"
                        :value="key"
                        :disabled="value.disabled"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>

                <v-tab-item>
                  <v-row
                    dense
                    justify="center"
                    align="center"
                  >
                    <v-col
                      v-for="(value, key) in dynamicCallHeaders"
                      :key="value.text"
                      sm="4"
                      md="3"
                      lg="3"
                    >
                      <v-checkbox
                        v-model="selectedDynamicHeaders"
                        :label="value.text"
                        :value="key"
                        :disabled="value.disabled"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              @click="showFieldsDialog = false"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              @click="toggleFields"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="showNameQuery"
        width="500"
      >
        <v-card>
          <v-toolbar color="primary">
            <v-toolbar-title class="white--text">
              Configure Report
            </v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <v-icon class="mr-3 mt-2">
                      dashboard
                    </v-icon>Add as widget
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card class="px-5 mb-3">
                    <v-layout column>
                      <v-flex class="ml-3 mr-5 pt-2">
                        <v-text-field
                          v-model="queryName"
                          label="Widget title"
                        />
                      </v-flex>
                      <v-flex class="ml-2 mb-2">
                        <v-btn
                          color="primary"
                          @click="addWidget"
                        >
                          Save
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <v-icon class="mr-3 mt-2">
                      event
                    </v-icon>Schedule Report
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ScheduleEditor
                    :edit-mode="false"
                    :clear-trigger="clearTrigger"
                    @save="saveSchedule"
                    @cancel="closeDialog"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-card>
      </v-dialog>
      <field-sorter
        :show="showFieldSorter"
        :fields="selectedFields"
        :field-names="{ ...headers, ...dynamicCallHeaders }"
        @close="showFieldSorter = false"
        @save="reOrderFields"
      />
    </div>

    <component
      :is="editedItem.item.category"
      v-if="showEditFilter"
      :item="editedItem.item"
      :query="editedItem.query"
      :edit-mode="true"
      @submit="onSubmit"
    />
  </v-container>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import { mapGetters } from 'vuex';
import { v4 } from 'uuid';
import { sortBy, isNumber } from 'lodash';
import permittedFunction from '@/permittedFunction.js';

import {
  bizvu_schedule_url,
  bizvu_analytics_url,
  feature_analytics_price,
  analytics_query_size,
} from '@/config.js';

import { Chart } from 'chart.js';
import 'chartjs-plugin-colorschemes';
import ForwardedCalls from '@/components/query/components/ForwardedCalls';
import account from './components/account/account';
import answerTime from './components/answerTime/answerTime';
import callflow from './components/callflow/callflow';
import callType from './components/callType/callType';
import destination from './components/destination/destination';
import date from './components/date/date';
import DCLOverflow from './components/DCLOverflow/DCLOverflow';
import direction from './components/direction/direction';
import duration from './components/duration/duration';
import mosScore from './components/mosScore/mosScore';
import sourceNumber from './components/sourceNumber/sourceNumber';
import destinationNumber from './components/destinationNumber/destinationNumber';
import numberFilters from './components/numberFilters/numberFilters';
import outOfHours from './components/outOfHours/outOfHours';
import price from './components/price/price';
import rateType from './components/rateType/rateType';
import status from './components/status/status';
import trunkId from './components/trunkId/trunkId';
import queryTable from './components/queryTable/queryTable';
import lostCalls from './components/lostCalls/lostCalls';
import DynamicallyRoutedCallFilter from './components/DynamicallyRoutedCallFilter';
import noneGeo from './components/noneGeo/noneGeo';
import FieldSorter from './FieldSorter';
import moment from 'moment-timezone';
import 'moment-timezone';
moment.tz.setDefault('UTC');

import QueryDownloader from './QueryDownloader';

import ScheduleEditor from '../schedule/ScheduleEditor';

export default {
  name: 'QueryBuilder',
  components: {
    account,
    answerTime,
    callflow,
    callType,
    noneGeo,
    date,
    DCLOverflow,
    destination,
    direction,
    duration,
    mosScore,
    sourceNumber,
    destinationNumber,
    numberFilters,
    outOfHours,
    price,
    rateType,
    status,
    trunkId,
    queryTable,
    lostCalls,
    DynamicallyRoutedCallFilter,
    ScheduleEditor,
    QueryDownloader,
    FieldSorter,
    ForwardedCalls,
  },
  data() {
    const headers = {
      account_name: { text: 'Account Name', disabled: false },
      answersec: { text: 'Answer Time', disabled: false },
      call_type: { text: 'Call Type', disabled: false },
      callflow_name: { text: 'CallFlow Name', disabled: false },
      blacklisted_comment: { text: 'Comment - Blacklisted', disabled: false },
      whitelisted_comment: { text: 'Comment - Whitelisted', disabled: false },
      destination_e164: { text: 'Dialled #', disabled: false },
      destination_country_name: {
        text: 'Destination Country',
        disabled: false,
      },
      destination_region: { text: 'Destination Region', disabled: false },
      call_direction: { text: 'Direction', disabled: false },
      billsec: { text: 'Duration', disabled: false },
      rtp_audio_in_mos: { text: 'MOS', disabled: false },
      out_of_hours: { text: 'Out of Hours', disabled: false },
      remote_sip_p_asserted_identity: { text: 'PAID Destination', disabled: false },
      sip_p_asserted_identity: { text: 'PAID Source', disabled: false },
      call_price: { text: 'Price', disabled: false },
      rate_type: { text: 'Rate Type', disabled: false },
      biz_ring_time: { text: 'Ring Time', disabled: false },
      source_e164: { text: 'Source #', disabled: false },
      call_status: { text: 'Status', disabled: false },
      call_datetime_iso: { text: 'Time', disabled: false },
      trunk_description: { text: 'Trunk', disabled: false },
      duration: { text: 'Total Call Time', disabled: false },
    };

    if (!feature_analytics_price) {
      delete headers.call_price;
    }

    const availableFilters = [
      { text: 'Answer Time', category: 'answerTime', editable: true },
      { text: 'Call Flow', category: 'callflow', editable: true },
      { text: 'Call Type', category: 'callType', editable: true },
      { text: 'Date/Time', category: 'date', editable: true },
      { text: 'DCL Overflow', category: 'DCLOverflow', editable: true },
      { text: 'Destination(s)', category: 'destination', editable: true },
      { text: 'Direction', category: 'direction', editable: true },
      { text: 'Duration', category: 'duration', editable: true },
      {
        text: 'Dynamically Routed Calls',
        category: 'DynamicallyRoutedCallFilter',
        editable: true,
      },
      { text: 'Forwarded Calls', category: 'ForwardedCalls', editable: false },
      { text: 'Lost Calls', category: 'lostCalls', editable: true },
      { text: 'MOS Score', category: 'mosScore', editable: true },
      { text: 'Source Number(s)', category: 'sourceNumber', editable: true },
      {
        text: 'Destination Number(s)',
        category: 'destinationNumber',
        editable: true,
      },
      { text: 'Number Filters', category: 'numberFilters', editable: true },
      { text: 'Out of Hours', category: 'outOfHours', editable: true },
      { text: 'Inbound NONGEO', category: 'noneGeo', editable: true },
      { text: 'Price', category: 'price', editable: true },
      { text: 'Rate Type', category: 'rateType', editable: true },
      { text: 'Status', category: 'status', editable: true },
      { text: 'Trunk', category: 'trunkId', editable: true },
    ];

    if (this.isPermitted('/tenants/<tenant_name>/analytics', 'POST')) {
      availableFilters.push({
        text: 'Account Override',
        category: 'account',
        editable: true,
      });
    }

    return {
      histogramData: [],
      histogramData: {
      labels: [],
      datasets: [],
      totalIncomingCalls: 0, // Total number of incoming calls
      totalOutgoingCalls: 0, // Total number of outgoing calls
      totalCallTime: 0,// Total call time
      isGrouped: false,
    },
      chart: null,
      showCanvas: true,
      chips: [],
      items: availableFilters,
      query: {},
      parameters: [],
      showList: false,
      showFieldsDialog: false,
      loading: false,
      records: [],
      totalHits: 0,
      showNameQuery: false,
      queryName: '',
      modalPanels: [1, 0],
      editScheduleId: '',
      editingScheduleQuery: false,
      showEditFilter: false,
      editedItem: null,
      scheduleToEdit: {},
      showReportType: false,
      selectedReportType: 'xlsx',
      // defaults
      displayHeaders: [
        { text: 'Direction', value: 'call_direction' },
        { text: 'Duration', value: 'billsec' },
        { text: 'Source #', value: 'source_e164' },
        { text: 'Status', value: 'call_status' },
        { text: 'Time', value: 'call_datetime_iso' },
        
      ],
      selectedFields: [
        'source_e164',
        'call_direction',
        'call_status',
        'billsec',
        'call_datetime_iso',
      ],
      showFieldSorter: false,
      // register new headers here
      headers,
      dynamicCallHeaders: {
        dynamic_routing: { text: 'Dynamically Routed', disabled: false },
        dynamic_routing_url: { text: 'API URL', disabled: false },
        dynamic_routing_cli: { text: 'CLI', disabled: false },
        dynamic_routing_api_response_route_info: {
          text: 'API Response',
          disabled: false,
        },
        dynamic_routing_payload: { text: 'API Payload', disabled: false },
        dynamic_routing_api_response_status: {
          text: 'API Response Status',
          disabled: false,
        },
        dynamic_routing_api_response_reason: {
          text: 'API Response Reason',
          disabled: false,
        },
        dynamic_routing_api_response_time: {
          text: 'API Response Time',
          disabled: false,
        },
        called_numbers: { text: 'Called Numbers', disabled: false },
        dcr_call_statuses: { text: 'Call Statuses', disabled: false },
        dynamic_routing_original_ddi: { text: 'Original DDI', disabled: false },
        dynamic_routing_alternative_ddi: {
          text: 'Alternative DDI',
          disabled: false,
        },
        dynamic_routing_alternative_routes: {
          text: 'Alternative Routes',
          disabled: false,
        },
        dynamic_routing_ring_timeout: { text: 'Ring Timeout', disabled: false },
        dynamic_routing_api_timeout: { text: 'API Timeout', disabled: false },
        dynamic_routing_endpoint_id: {
          text: 'API Endpoint ID',
          disabled: false,
        },
        dynamic_routing_endpoint_version: {
          text: 'API Version',
          disabled: false,
        },
      },
      fieldTab: 0,
      selectedDynamicHeaders: [],
      tableHeight: 200,
      clearTrigger: false,
      analytics_query_size,
      includeNonGeoCalls: true,
      noDataText: null,
      showIncoming: true,
      showOutgoing: true,
      records: []
    };
    
  },
  computed: {
    timeZone() {
      return this.$store.getters.timeZone;
    },
    startOfCurrentMonth() {
      return moment().tz(this.timeZone).startOf('month').toISOString();
    },
    endOfCurrentMonth() {
      return moment().tz(this.timeZone).endOf('month').toISOString();
    },
    currentMonthHuman() {
      return moment().tz(this.timeZone).format('MMMM Y');
    },
    isSixMonthSummaryActive() {
      return this.chips.some(chip => chip.item.text === "Summary");
    },
    filteredCalls() {
    return this.records.filter(record => {
      return (this.showIncoming && record.call_direction === 'INCOMING') ||
             (this.showOutgoing && record.call_direction === 'OUTGOING');
    });
  },
  successfulCalls() {
    return this.filteredCalls.filter(record => record.call_status === 'SUCCESS').length;
  },
  abandonedCalls() {
    return this.filteredCalls.filter(record => record.call_status === 'ORIGINATOR_CANCEL').length;
  },
  otherStatusesCalls() {
    const otherStatuses = this.filteredCalls.filter(
      record => record.call_status !== 'SUCCESS' && record.call_status !== 'ORIGINATOR_CANCEL'
    );
    const totalOtherStatuses = otherStatuses.length;
    const percentageOtherStatuses = this.totalCalls > 0 ? ((totalOtherStatuses / this.totalCalls) * 100).toFixed(2) : '0.00';
    return {
      totalOtherStatuses,
      percentageOtherStatuses
    };
  },
  totalCalls() {
    return this.filteredCalls.length;
  },
  percentageSuccessfulCalls() {
    if (this.successfulCalls === 0) {
      return '0.00';
    }
    return this.totalCalls > 0 ? ((this.successfulCalls / this.totalCalls) * 100).toFixed(2) : '0.00';
  },
  percentageAbandonedCalls() {
    if (this.abandonedCalls === 0) {
      return '0.00';
    }
    return this.totalCalls > 0 ? ((this.abandonedCalls / this.totalCalls) * 100).toFixed(2) : '0.00';
  },
  percentageSuccessfulIncomingCalls() {
    const successfulIncoming = this.records.filter(record => record.call_status === 'SUCCESS' && record.call_direction === 'INCOMING').length;
    if (successfulIncoming === 0) {
      return '0.00';
    }
    return this.totalIncomingCalls > 0 ? (successfulIncoming / this.totalIncomingCalls * 100).toFixed(2) : '0.00';
  },
  percentageAbandonedIncomingCalls() {
    const abandonedIncoming = this.records.filter(record => record.call_status === 'ORIGINATOR_CANCEL' && record.call_direction === 'INCOMING').length;
    if (abandonedIncoming === 0) {
      return '0.00';
    }
    return this.totalIncomingCalls > 0 ? (abandonedIncoming / this.totalIncomingCalls * 100).toFixed(2) : '0.00';
  },
  percentageSuccessfulOutgoingCalls() {
    const successfulOutgoing = this.records.filter(record => record.call_status === 'SUCCESS' && record.call_direction === 'OUTGOING').length;
    if (successfulOutgoing === 0) {
      return '0.00';
    }
    return this.totalOutgoingCalls > 0 ? (successfulOutgoing / this.totalOutgoingCalls * 100).toFixed(2) : '0.00';
  },
  percentageAbandonedOutgoingCalls() {
    const abandonedOutgoing = this.records.filter(record => record.call_status === 'ORIGINATOR_CANCEL' && record.call_direction === 'OUTGOING').length;
    if (abandonedOutgoing === 0) {
      return '0.00';
    }
    return this.totalOutgoingCalls > 0 ? (abandonedOutgoing / this.totalOutgoingCalls * 100).toFixed(2) : '0.00';
  },
  totalCallTime() {
    // Filter records based on the selected incoming and outgoing checkboxes
    const filteredRecords = this.records.filter(record => {
      return (this.showIncoming && record.call_direction === 'INCOMING') ||
             (this.showOutgoing && record.call_direction === 'OUTGOING');
    });

    // Assuming each record has a 'duration' field representing call duration in seconds
    const totalSeconds = filteredRecords.reduce((acc, record) => {
      // Ensure 'record.duration' exists and is a number
      if (record && typeof record.duration === 'number') {
        return acc + record.duration; // Sum durations
      } else {
        console.warn('Invalid record or duration:', record);
        return acc; // Handle this case as needed
      }
    }, 0);

    // Convert total seconds to minutes and round to two decimal places
    const totalMinutes = (totalSeconds / 60).toFixed(2);
    return totalMinutes;
  },
        // Compute total incoming calls
        totalIncomingCalls() {
      return this.records.reduce((total, record) => {
        if (record.call_direction === 'INCOMING') {
          return total + 1;
        }
        return total;
      }, 0);
    },

    // Compute total outgoing calls
    totalOutgoingCalls() {
      return this.records.reduce((total, record) => {
        if (record.call_direction === 'OUTGOING') {
          return total + 1;
        }
        return total;
      }, 0);
    },
    userPreferences() {
      return this.$store.state.userPreferences;
    },
    categories() {
      // eslint-disable-next-line no-nested-ternary
      return [...this.items].sort((a, b) => (a.text.toLowerCase() === b.text.toLowerCase()
        ? 0
        : a.text.toLowerCase() > b.text.toLowerCase()
          ? 1
          : -1));
    },
    reportDownloadName() {
      if (this.chips.length === 0) {
        return 'Analytics';
      }

      const dateTimeFilter = this.chips.find(
        (reportFilter) => reportFilter.item.type === 'datetime',
      );
      const filtersExcludingDateTime = this.chips.filter(
        (reportFilter) => reportFilter.item.type !== 'datetime',
      );

      const reportName = filtersExcludingDateTime
        .map((reportFilter) => {
          if (reportFilter.text) {
            return `${reportFilter.item.text}(${reportFilter.text})`;
          }
          return reportFilter.item.text;
        })
        .join('&');

      if (dateTimeFilter) {
        if (reportName) {
          return `${reportName}____${dateTimeFilter.text}`;
        }
        return dateTimeFilter.text;
      }

      return reportName;
    },
    hitsExceedResultsLimit() {
      return this.totalHits > analytics_query_size;
    },
    ...mapGetters(['selectedAccountId', 'authentication', 'timeZone']),
  },
  watch: {
    showCanvas(newVal) {
      // Watch for changes in showCanvas and adjust visibility
      this.toggleCanvasVisibility();
    },
    records() {
      this.updateChart();
    },
    chips() {
      this.buildQuery();
    },
    showNameQuery() {
      if (!this.showNameQuery) {
        this.clearSchedule();
      }
    },
    '$route.query.scheduleId': function (scheduleId) {
      if (scheduleId) {
        this.parseURLForChips();
      }
    },
  },
  mounted() {
  this.setDefaultNoDataText();
  this.selectedFields = (
    JSON.parse(localStorage.getItem('btlnet_analytics_headers'))
    || this.selectedFields
  ).filter((header) => Object.keys(this.headers).includes(header));

  this.selectedDynamicHeaders = JSON.parse(localStorage.getItem('btlnet_analytics_dynamic_headers'))
    || this.selectedDynamicHeaders;

  this.determineFieldsToShow();
  this.parseURLForChips();
  this.onResize();
  this.parseURLForChips();

  // Execute the query on page load
  this.generateHistogramData();
  this.executeQuery();
  this.toggleCanvasVisibility();

  // Apply default 'Current Month' filter if no filters are present
  const hasFilters = Object.keys(this.$route.query).length > 0;

  if (!hasFilters) {
    this.applyDefaultTodayFilter(); // Apply default 'Current Month' filter
  }
},
  methods: {
    toggleHistogram() {
      this.showCanvas = !this.showCanvas;
    },
    toggleGroupByMonth() {
      this.isGrouped = !this.isGrouped;
    },
    parseURLForChips() {
      const params = this.$route.query;
      const scheduleId = params.scheduleId || null;
      delete params.scheduleId;

      // Check if there are any filters in the URL
      const hasFilters = Object.keys(params).length > 0;

      if (hasFilters) {
        // Apply filters from URL
        Object.values(params).forEach((param) => {
          try {
            const chip = JSON.parse(param);
            console.log('Applying URL filter:', chip); // Debug
            this.onSubmit({
              item: chip.item,
              query: chip.query,
              text: chip.text,
              parameters: chip.parameters
            });
          } catch (error) {
            console.error('Error parsing URL parameter:', error);
          }
        });
      } else {
        // Apply the default filter if no filters are found
        console.log('Applying default filter'); // Debug
        this.applyDefaultPast24HoursFilter();
      }

      if (scheduleId) {
        this.editScheduleId = scheduleId;
        this.editingScheduleQuery = true;
        this.fetchSchedule(scheduleId);
      }
    },

    applyDefaultPast24HoursFilter() {
      // Get the current date and time
      const now = new Date();
      
      // Calculate the date and time 24 hours ago
      const past24Hours = new Date(now);
      past24Hours.setHours(now.getHours() - 24);

      // Create the filter object for "Past 24 Hours"
      const defaultDateFilter = {
        item: {
          category: 'date',
          type: 'past24Hours', // Ensure this matches your filter type
          text: 'Last 24 Hours',
          editable: false
        },
        query: {
          query: {
            bool: {
              filter: [
                {
                  range: {
                    call_datetime_iso: {
                      gte: past24Hours.toISOString(),
                      lte: now.toISOString()
                    }
                  }
                }
              ]
            }
          }
        },
        parameters: [
          {
            fieldName: 'call_datetime_iso',
            type: 'DATE_PAST_24_HOURS' // Ensure this matches your parameter type
          }
        ]
      };
      
      // Submit the filter
      this.onSubmit(defaultDateFilter);
    },

    buildQuery() {
      const _query = {
        index: 'analytics',
        type: 'analytics',
        body: {
          aggs: {},
          query: {
            bool: {},
          },
          sort: [
            {
              call_datetime_iso: 'desc',
            },
          ],
        },
      };

      let parameters = [];
      this.chips.forEach(({ query, parameters: params }) => {
        if (Object.prototype.hasOwnProperty.call(query, 'query')) {
          const q = query.query;
          Object.keys(q.bool).forEach((key) => {
            if (Array.isArray(q.bool[key])) {
              _query.body.query.bool[key] = _query.body.query.bool[key] || [];
              q.bool[key].forEach((i) => {
                _query.body.query.bool[key].push(i);
              });
            } else {
              _query.body.query.bool[key] = q.bool[key];
            }
          });
          if (params && params.length > 0) {
            parameters = parameters.concat(params);
          }
        }
      });

      this.query = _query;
      this.parameters = parameters;
    },
    toggleFields() {
      this.showFieldsDialog = !this.showFieldsDialog;
      if (!this.showFieldsDialog) {
        this.determineFieldsToShow();
      }
    },
    selectAll() {
      if (this.fieldTab === 0) {
        this.selectedFields = [];
        Object.keys(this.headers).forEach((value) => {
          this.selectedFields.push(value);
        });
      } else {
        this.selectedDynamicHeaders = [];
        Object.keys(this.dynamicCallHeaders).forEach((value) => {
          this.selectedDynamicHeaders.push(value);
        });
      }
    },
    selectNone() {
      if (this.fieldTab === 0) {
        this.selectedFields = [];
      } else {
        this.selectedDynamicHeaders = [];
      }
    },
    determineFieldsToShow() {
      localStorage.setItem(
        'btlnet_analytics_headers',
        JSON.stringify(this.selectedFields),
      );
      localStorage.setItem(
        'btlnet_analytics_dynamic_headers',
        JSON.stringify(this.selectedDynamicHeaders),
      );
      this.displayHeaders = this.selectedFields
        .map((value) => {
          const text = this.headers[value];
          return { text, value };
        })
        .concat(
          this.selectedDynamicHeaders.map((value) => {
            const text = this.dynamicCallHeaders[value];
            return { text, value };
          }),
        );
    },
    nameQueryDialog() {
      this.queryName = this.chips
      .map((chip) => {
        if (chip.text) {
          return `${chip.item.text}: ${chip.text.replace(/\[.*\]/, '')}`;
        }
        return `${chip.item.text}: No data`;  // Default fallback text or handle as per requirement
      })
      .join(', ');
      this.showNameQuery = !this.showNameQuery;
    },
    addWidget() {
      const customQueryWidget = {
        id: v4(),
        type: 'CustomQuery',
        name: this.queryName,
        data: {
          headers: this.displayHeaders,
          query: this.query,
          parameters: this.parameters,
        },
      };
      this.$store.commit('addWidget', customQueryWidget);
      this.$store
        .dispatch('saveUserPreferences', this.userPreferences)
        .then(() => {
          this.$router.push('/dashboard');
        })
        .catch((error) => {
          console.error('failed to add custom query widget', error);
        });
    },
    saveSchedule(schedule) {
      const scheduleToCreate = {
        ...schedule,
      };

      scheduleToCreate.accountId = this.selectedAccountId;

      scheduleToCreate.queryParameters = this.$route.query;
      scheduleToCreate.elasticSearchQuery = this.query.body;

      scheduleToCreate.headerFields = this.displayHeaders.map((header) => ({ text: header.text.text, value: header.value }));

      axios
        .post(
          `${bizvu_schedule_url}/account/${this.authentication.userAccountId}/user/${this.authentication.userId}/schedule`,
          scheduleToCreate,
        )
        .then(() => {
          this.showNameQuery = false;
        });
    },
    clearSchedule() {
      this.clearTrigger = !this.clearTrigger;
    },
    fetchSchedule(scheduleId) {
      this.reportsLoading = true;
      axios
        .get(
          `${bizvu_schedule_url}/account/${this.authentication.userAccountId}/user/${this.authentication.userId}/schedule/${scheduleId}`,
        )
        .then((response) => {
          this.scheduleToEdit = response.data;
        });
    },
    async updateSchedule() {
      this.scheduleToEdit.queryParameters = this.$route.query;
      this.scheduleToEdit.elasticSearchQuery = this.query.body;
      try {
        await axios.put(
          `${bizvu_schedule_url}/account/${this.authentication.userAccountId}/user/${this.authentication.userId}/schedule/${this.editScheduleId}`,
          this.scheduleToEdit,
        );
        this.$emit('snack', `Schedule ${this.scheduleToEdit.name} Updated!`);
        this.editingScheduleQuery = false;
        this.chips = [];
        this.$router.push('/analytics');
      } catch (err) {
        this.$emit('snack', 'Failed up update schedule', true);
      }
    },
    onClear() {
      this.chips = [];
      this.records = [];
      this.updateURL();
      this.setDefaultNoDataText();
    },
    updateItemValue(item) {
      const oItem = this.items.find(
        (row) => row.text === item.text && row.category === item.category,
      );
      if (oItem && item.defaultValue) {
        oItem.defaultValue = item.defaultValue;
      }
    },
    onSubmit({ item, query, text, parameters }) {
    this.updateItemValue(item);

    // Find index of existing filter type
    const index = this.chips.findIndex(chip => chip.item.type === item.type);

    if (index === -1) {
      // Add new filter
      this.chips.push({ item, query, text, parameters });
    } else {
      // Update existing filter
      this.chips[index].query = query;
      this.chips[index].text = text;
      this.chips[index].parameters = parameters;
      this.chips[index].item = item;
    }

    // Rebuild the query
    this.buildQuery();

    // Automatically execute the query after filter is applied
    this.executeQuery();

    // Close the filter selection dropdown
    this.showList = false;

    // Optionally sort the filters for display
    this.sortSelectedFilters();

    // Update the URL with the new filters
    this.updateURL();
  },
    sortSelectedFilters() {
      this.chips = sortBy(this.chips, 'item.text');
    },
    /* eslint-disable */ 
    executeQuery() {
      this.setDefaultNoDataText();
      const query = this.query.body;
      query.from = 0;
      query.size = analytics_query_size;
      const params = {
        userquery: JSON.stringify(query),
        userId: this.authentication.userId,
        accountId: this.authentication.accountId,
        reportType: 'querybuilder',
      }; // Added trailing comma here
      this.loading = true;
      axios
        .get(
          `${bizvu_analytics_url}/account/${this.selectedAccountId}/userquery`,
          { params } // Added trailing comma here
        )
        .then((response) => {
          this.loading = false;
          this.records = [];
          this.totalHits = isNumber(response.data.hits.total)
            ? response.data.hits.total
            : response.data.hits.total.value; // ES v6 or ES v7
          response.data.hits.hits.forEach((r) => {
            r._source.biz_ring_time = r._source.duration - r._source.billsec;
            this.records.push(r._source);
          });
          this.buildQuery();
          this.onResize();
          this.updateChart(); // Ensure the chart is updated after fetching records
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status === 404) {
            this.noDataText = 'No call data records found';
          } else {
            console.error('failed to execute analytics query', error);
            this.$emit('snack', 'Failed to execute analytics query', true);
          }
        });
    },
generateHistogramData() {
  const callData = {};
  const timeZone = 'Europe/London';  // UK timezone

  // Determine min and max times from the records (after shift)
  const recordTimes = this.records.map(record => {
    const recordDate = new Date(record.call_datetime_iso);
    recordDate.setHours(recordDate.getHours() + 1);  // Shift by 1 hour forward
    return recordDate.getTime();
  });
  const minTime = Math.min(...recordTimes);
  const maxTime = Math.max(...recordTimes);

  const timeSpan = maxTime - minTime;
  this.isLast24Hours = timeSpan <= 24 * 60 * 60 * 1000;  // Store this flag for chart rendering

  this.records.forEach(record => {
    const recordDate = new Date(record.call_datetime_iso);
    const localDate = new Date(recordDate.toLocaleString('en-US', { timeZone }));

    // Apply the 1-hour shift forward
    localDate.setHours(localDate.getHours() + 1);  // Shift by 1 hour forward

    let key;
    if (this.isLast24Hours) {
      const startOfHour = new Date(localDate);
      startOfHour.setMinutes(0, 0, 0);  // Round down to the start of the hour
      key = startOfHour.getTime();  // Get timestamp for the start of the hour
    } else {
      key = new Date(localDate.getFullYear(), localDate.getMonth(), localDate.getDate()).getTime();  // Group by day if outside 24 hours
    }

    if (!callData[key]) {
      callData[key] = { incoming: 0, outgoing: 0 };
    }

    const direction = record.call_direction;
    if (direction === 'INCOMING') {
      callData[key].incoming++;
    } else if (direction === 'OUTGOING') {
      callData[key].outgoing++;
    }
  });

  // Create hourly ranges and ensure we don't add leading empty slots
  if (this.isLast24Hours) {
    const startHour = new Date(minTime);
    startHour.setMinutes(0, 0, 0);

    const endHour = new Date(maxTime);
    endHour.setMinutes(0, 0, 0);

    const hourlyRange = [];
    for (let time = startHour.getTime(); time <= endHour.getTime(); time += 60 * 60 * 1000) {
      // Only add empty slots within the active time range (after shift)
      if (!callData[time] && time >= minTime) {
        callData[time] = { incoming: 0, outgoing: 0 };  // Fill missing hours within the range with 0 data
      }
      hourlyRange.push(time);
    }
  }

  // Sort the call data by keys
  const sortedKeys = Object.keys(callData).sort((a, b) => Number(a) - Number(b));

  const sortedDatasets = sortedKeys.map(key => ({
    label: key,
    incoming: callData[key].incoming || 0,
    outgoing: callData[key].outgoing || 0,
  }));

  this.histogramData = {
    labels: sortedKeys.map(label => {
      const date = new Date(Number(label));
      return this.isLast24Hours
        ? date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', timeZone })
        : date.toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', timeZone });
    }),
    datasets: [],
  };

  if (this.showIncoming) {
    this.histogramData.datasets.push({
      label: 'Incoming Calls',
      backgroundColor: 'rgba(255, 159, 64, 0.6)',
      data: sortedDatasets.map(data => data.incoming),
      stack: 'stack1',
    });
  }

  if (this.showOutgoing) {
    this.histogramData.datasets.push({
      label: 'Outgoing Calls',
      backgroundColor: 'rgba(33,150,243, 0.6)',
      data: sortedDatasets.map(data => data.outgoing),
      stack: 'stack1',
    });
  }

  // Total call time calculation
  this.totalCallTime = this.records.reduce((acc, curr) => acc + curr.call_duration_seconds, 0);
},

renderChart() {
  const ctx = document.getElementById('myChart').getContext('2d');
  Chart.defaults.scale.gridLines.drawOnChartArea = false;
  Chart.defaults.global.legend.display = false;

  if (this.chart) {
    this.chart.destroy();
  }

  // Custom plugin to draw labels on the x-axis
  const customPlugin = {
    beforeDraw: function(chart) {
      // Only show "Oldest" and "Newest" if in hourly view (last 24 hours)
      if (this.isLast24Hours) {
        const { ctx, chartArea } = chart;
        const { left, right, bottom } = chartArea;

        // Set text style
        ctx.save();
        ctx.font = 'normal 12px Arial'; // Change font weight to normal
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = '#000'; // Black color

        // Adjust the y position of the labels to be above the chart
        const labelYOffset = 40; // Adjust this value as needed

        // Draw "Oldest" on the left side of the x-axis
        ctx.fillText('Oldest', left + 10, bottom + labelYOffset); // Adjust position as needed

        // Draw "Newest" on the right side of the x-axis
        ctx.fillText('Newest', right - 20, bottom + labelYOffset); // Adjust position as needed

        ctx.restore();
      }
    }.bind(this), // Ensure 'this' refers to the component instance
  };

  this.chart = new Chart(ctx, {
    type: 'bar',
    data: this.histogramData,
    options: {
      responsive: true,
      layout: {
        padding: {
          top: 0,
          right: 2, // Increase space on the right for "Newest"
          bottom: 25, // Increase space at the bottom for "Oldest"
          left: 2, // Increase space on the left if necessary
        },
      },
      scales: {
        xAxes: [{
          gridLines: {
            drawOnChartArea: false,
          },
          stacked: true,
          scaleLabel: {
            display: true,
          },
        }],
        yAxes: [{
          gridLines: {
            drawOnChartArea: false,
          },
          stacked: true,
          ticks: {
            beginAtZero: true,
            stepSize: 1, // Set the step size to 1 to ensure whole numbers
            callback: function(value) {
              // Format the y-axis labels as integers
              return Number.isInteger(value) ? value : '';
            }
          },
          scaleLabel: {
            display: true,
          },
        }],
      },
      legend: {
        display: true,
        position: 'top',
      },
      plugins: {
        customPlugin: true, // Register the custom plugin
      },
    },
    plugins: [customPlugin], // Use the custom plugin
  });
},
    toggleGrouping() {
      this.isGrouped = this.isGrouped;
      this.updateChart();
    },
    toggleCanvasVisibility() {
    const canvasWrapper = this.$refs.canvasWrapper;
    const card = canvasWrapper.closest('.row').querySelector('.col-lg-4');

    if (this.showCanvas) {
      canvasWrapper.classList.remove('hidden');
      card.style.marginLeft = '0'; // Reset margin if the canvas is shown
    } else {
      canvasWrapper.classList.add('hidden');
      card.style.marginLeft = '0'; // Collapse space occupied by the canvas
    }
  },
  
  // Ensure to call `toggleCanvasVisibility` method after data changes if required
  updateChart() {
    this.generateHistogramData();
    this.renderChart();
    this.toggleCanvasVisibility(); // Ensure to call here if it's linked to data change
  },
    editFilter(chip) {
      this.editedItem = null;
      this.showEditFilter = false;
      // need this if you keep editing the same filter to reset the state
      setTimeout(() => {
        this.editedItem = chip;
        this.showEditFilter = chip.item.type;
      }, 0);
    },
    updateURL() {
      const query = {};
      this.chips.forEach((chip) => {
        query[chip.item.category] = JSON.stringify({
          query: chip.query,
          item: chip.item,
          text: chip.text,
          parameters: chip.parameters,
        });
      });
      this.$router.push({ path: window.location.path, query });
    },
  onClose({ item }) {
    // Find the index of the chip to remove
    const index = this.chips.findIndex(chip => chip.item === item);

    if (index !== -1) {
      // Remove the chip from the array
      this.chips.splice(index, 1);

      // Update the URL to reflect the removal of the filter
      this.updateURL();

      // Rebuild the query based on the remaining filters
      this.buildQuery();

      // Check if there are any remaining filters
      if (this.chips.length > 0) {
        // Execute the query if there are still filters
        this.executeQuery();
      } else {
        // Optionally handle the case where no filters are present
        // For example, you could clear the results or apply a default query
        this.records = []; // Clear previous records
        this.setDefaultNoDataText(); // Set default no-data text
      }
    }
  },
    onResize() {
      this.tableHeight = this.hitsExceedResultsLimit
        ? this.$refs.table.clientHeight - 10
        : this.$refs.table.clientHeight + 30;
    },
    closeDialog() {
      this.showNameQuery = false;
    },
    reOrderFields(reOrderedFields) {
      this.selectedFields = reOrderedFields;
      this.determineFieldsToShow();
      this.showFieldSorter = false;
    },
    isPermitted(endpoint, verb) {
      return permittedFunction(endpoint, verb);
    },
    setDefaultNoDataText() {
      this.noDataText = 'No data available';
    },
  },
};
</script>

<style>
  /* General styles */
  .container-fluid {
    padding-top: 20px; /* Adjust top padding as needed */
  }

  .highlighted-section {
    background-color: #f8f9fa; /* Light gray background */
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }

  .highlighted-section h6 {
    font-size: 14px;
    color: #6c757d; /* Gray text color */
    margin-bottom: 0.5rem; /* Adjust margin for consistency */
  }

  .highlighted-section .form-check {
    margin-right: 5px; /* Adjust spacing between title and checkbox */
  }

  .group-title {
    font-weight: normal;
  }

  .group-toggle {
    margin-top: 2em;
    margin-left: 10px;
  }

  /* Toolbar and search styles */
  .v-toolbar {
    height: unset !important;
  }

  .v-toolbar > div {
    height: unset !important;
    display: flex;
    flex-wrap: wrap;
  }

  .search-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .search-wrapper > div {
    display: flex;
    flex-direction: row;
  }

  .search-input {
    flex-grow: 1;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  }

  .search-input > div > .v-chip {
    height: unset;
    min-height: 32px;
    white-space: normal;
    overflow: hidden;
  }

  /* Typography */
  .card-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-right: 7em; /* Adjust margin as needed */
    margin-bottom: 1em;
  }

  h6 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1rem;
    margin: 0;
  }

  .form-check-label {
    font-size: 0.875rem;
  }

  /* Form switch toggle */
  .form-switch input {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  .form-switch label {
    cursor: pointer;
    width: 40px;
    height: 20px;
    background-color: #ccc;
    display: inline-block;
    position: relative;
    border-radius: 20px;
  }

  .form-switch label:after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #fff;
    top: 1px;
    left: 1px;
    transition: 0.3s;
  }

  .form-switch input:checked + label {
    background-color: #2fa0ff; /* Toggle button active background color */
  }

  .form-switch input:checked + label:after {
    left: calc(100% - 1px);
    transform: translateX(-100%);
  }
  .canvas-wrapper {
  transition: max-height 0.3s ease-out;
  overflow: hidden;
  /* Adjust based on the height of the canvas */
}

.canvas-wrapper.hidden {
  max-height: 0;
  opacity: 0; /* Hide the element smoothly */
}

.card {
  transition: width 0.3s ease;
}

.highlighted-section-wrapper {
  display: flex;
  flex-direction: column;
}

.highlighted-section-wrapper-row {
  flex-direction: row;
  flex-wrap: wrap;
}

.highlighted-section {
  flex: 1;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.highlighted-section:last-child {
  margin-right: 0;
}

/* Toggle buttons layout */
.toggle-buttons-wrapper {
  display: flex;
  flex-direction: column;
}

.toggle-buttons-wrapper-row {
  flex-direction: row;
  flex-wrap: wrap;
}

.toggle-buttons-wrapper > div {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.toggle-buttons-wrapper > div:last-child {
  margin-right: 0;
}

.group-toggle {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.subheading-section{
  margin-bottom: 5px;
}

.stats-header {
  display: flex;
 /* Adjusts the spacing to distribute items */
  align-items: center; /* Aligns items vertically in the center */
  flex-wrap: wrap; /* Allows items to wrap in smaller screens */
  justify-content: space-between;
}

.card-title {
  margin-bottom: 0; /* Remove margin if not needed */
  margin-left: 20px;
}

.toggle-buttons-wrapper {
  display: flex;
  flex-direction: row; /* Ensures the toggle buttons are in a row */
  align-items: center; /* Centers the toggle buttons vertically */
}

.stat-item {
  display: flex;
  align-items: center; /* Ensures vertical centering */
  justify-content: space-between; /* Distributes space between elements */
  width: 100%; /* Full width to spread content across the container */
}

.subheading {
  margin-right: 10px; /* Adds some space between the heading and the statistics */
  white-space: nowrap; /* Prevents the subheading from wrapping */
  flex: 1; /* Allows the subheading to take up necessary space */
}

.highlighted-section p {
  margin: 0; /* Removes default margin for consistency */
  text-align: right; /* Aligns the text to the right for a neat appearance */
}

.highlighted-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.stats-header {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  flex-wrap: wrap; /* Allows items to wrap if necessary */
}

.card-title {
  margin-right: 20px; /* Ensures some space between the title and the toggles */
  white-space: nowrap; /* Prevents the title from wrapping */
}

.toggle-buttons-wrapper {
  display: flex;
  flex-wrap: nowrap; /* Prevents the toggles from wrapping */
}

.group-toggle {
  margin-right: 10px; /* Spaces out individual toggles */
}

.group-toggle:last-child {
  margin-right: 0; /* Removes margin from the last toggle to avoid unnecessary spacing */
}

.table {
  margin-top: 20px;
}

.stats-header {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;  /* Ensure it spans the full container width */
}

.card-title {
  margin-right: 5px; /* Small margin to the right */
  flex-shrink: 0; /* Prevents the title from shrinking */
  margin-top: 10px;
}

.toggle-buttons-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; /* Aligns the buttons to the start (right after the title) */
}

.group-toggle {
  margin-right: 5px; /* Reduce margin between toggle switches */
}

.group-toggle:last-child {
  margin-right: 0; /* No margin on the last item */
}

.highlighted-section-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.narrow-section {
  flex: 1 1 20%; /* Adjusts to take less space */
}

.wide-section {
  flex: 1 1 40%; /* Takes more space when histogram is hidden */
}

.highlighted-section {
  padding: 15px;
  margin: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.group-toggle .form-check {
  display: flex;
  align-items: center; /* Centers the label and switch vertically */
  white-space: nowrap; /* Ensures text does not wrap */
}

.form-check-input {
  margin-right: 10px; /* Provides space between the switch and label */
}

.v-menu__content {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); /* Optional: Adds shadow for better visibility */
}

.toggle-label {
  margin-right: 10px;
}

.form-switch label {
  margin-left: 10px; /* Space between label text and the toggle */
}
.v-menu__content {
  min-width: 250px; /* Adjust as needed */
  width: auto !important; /* Ensures the dropdown width expands based on content up to a max width */
  max-width: 90%; /* Prevents it from being too wide on larger screens */
}
.options-button {
  margin-right: 20px;
}
.col-lg-4 {
  padding-bottom: 0 !important;
}

/* Base styles for mobile and tablet sizes */
.highlighted-section {
  padding: 25px;
  margin: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column; /* Stack vertically by default */
  align-items: flex-start; /* Align items to the start (left) */
}
.highlighted-section p {
  margin: 0;
  text-align: left; /* Ensure text aligns to the left */
}
/* Styles for desktop and larger screens */
@media (max-width: 2350px) { /* Adjust the min-width as needed for desktop */
  .highlighted-section {
    flex-direction: row; /* Side-by-side layout for desktops and larger screens */
    justify-content: space-between; /* Space items evenly */
    align-items: center; /* Center items vertically */
    padding: 15px;
  }
  .highlighted-section p {
    text-align: right; /* Align text to the right in side-by-side layout */
  }
}
/* Common text styling for highlighted sections */
.highlighted-text {
  font-family: Arial, sans-serif; /* Standard font family */
  font-size: 14px; /* Adjust font size as needed */
  color: #333; /* Darker text color for better readability */
}

/* Updated .highlighted-section styles */
.highlighted-section p,
.highlighted-section h6 {
  margin: 0;
  font-family: Arial, sans-serif; /* Ensure consistent font type */
  font-size: 14px; /* Ensure consistent font size */
}

/* Optional: Ensure heading (h6) and paragraphs (p) in highlighted sections have same styling */
.highlighted-section h6 {
  font-size: 14px; /* Adjust size if necessary */
  font-weight: normal; /* Ensure consistent weight */
}

.highlighted-section p {
  font-size: 14px; /* Ensure same size */
  color: #333; /* Consistent color */
}
@media (min-width: 1500px) and (max-width: 2201px) {
  .highlighted-section p {
    display: block;
    margin-left: 25px; /* Optional: Adjust the space between the statistics */
  }
}

@media (min-width: 2009px) and (max-width: 2350px) {
  .highlighted-section {
    padding: 20px;

  }
}

.card-title {
  margin-bottom: 0.5em; /* Adjust as needed */
  margin-top: 0.5em; /* Adjust as needed */
}

</style>