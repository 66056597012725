<template>
  <div>
    <!-- Toolbar -->
    <v-toolbar flat>
      <!-- Back Button -->
      <v-btn
        icon
        @click.stop="$router.back()"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <!-- Edit Title -->
      <v-toolbar-title class="mr-5">
        Edit - {{ account.name }}
      </v-toolbar-title>
      <!-- Activate/Deactivate Button -->
      <v-btn
        v-if="isPermitted('/tenants/<tenant_name>/accounts', 'POST')"
        text
        color="blue"
        @click="showAccountStateDialogue = true"
      >
        <v-icon class="mr-2">
          {{ isAccountActive ? 'lock' : 'lock_open' }}
        </v-icon> {{ isAccountActive ? 'Deactivate' : 'Activate' }}
      </v-btn>
      <!-- Archive Button -->
      <v-btn
        v-if="!account.Archived && isPermitted('/tenants/<tenant_name>/accounts', 'POST')"
        text
        color="blue"
        :loading="archivingRestoring"
        class="ml-2"
        @click="archive"
      >
        <v-icon class="mr-2">
          archive
        </v-icon>Archive
      </v-btn>
      <!-- Restore Button -->
      <v-btn
        v-if="account.Archived && isPermitted('/tenants/<tenant_name>/accounts/<account_id>/un-archive', 'POST')"
        text
        color="blue"
        :loading="archivingRestoring"
        class="ml-2"
        @click="restore"
      >
        <v-icon class="mr-2">
          unarchive
        </v-icon>Restore
      </v-btn>
      <v-spacer />
    </v-toolbar>
    <!-- Account Information Form -->
    <v-container>
      <!-- Display Alert for Archived Account -->
      <v-alert
        v-if="account.Archived"
        type="info"
      >
        Archived Account
      </v-alert>
      <!-- Account Edit Form -->
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <!-- Name Field -->
        <v-layout>
          <v-flex xs4>
            <v-text-field
              v-model="account.name"
              label="Name"
              :rules="nameRules"
              :counter="60"
              required
            />
          </v-flex>
        </v-layout>
        <!-- Address Fields -->
        <v-layout>
          <v-flex xs10>
            <v-text-field
              v-model="account.Address1"
              label="Street address 1"
              :rules="address1Rules"
              :counter="120"
              required
            />
            <v-text-field
              v-model="account.Address2"
              label="Street address 2"
              :rules="address2Rules"
              :counter="120"
              required
            />
          </v-flex>
        </v-layout>
        <!-- City, Postcode, and Country Fields -->
        <v-layout>
          <v-flex xs5>
            <v-layout>
              <v-text-field
                v-model="account.City"
                label="City"
                :rules="cityRules"
                :counter="60"
                required
              />
            </v-layout>
          </v-flex>
          <v-flex xs2>
            <v-layout>
              <v-text-field
                v-model="account.PostCode"
                label="Postcode"
                :rules="postcodeRules"
                :counter="10"
                required
                class="ml-2"
              />
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-layout>
              <v-text-field
                v-model="account.Country"
                label="Country"
                :rules="countryRules"
                :counter="30"
                required
                class="ml-2"
              />
            </v-layout>
          </v-flex>
        </v-layout>
        <!-- Find Address Section -->
        <v-layout class="mt-4">
          <p class="subtitle-1">
            Find new address:
          </p>
        </v-layout>
        <v-layout class="mb-4">
          <v-flex xs2>
            <v-text-field
              v-model="pcLookup"
              label="Postcode"
              :counter="10"
            />
          </v-flex>
          <v-flex
            xs2
            class="ml-3"
          >
            <v-btn
              class="mt-3"
              color="primary"
              text
              @click="findAddresses"
            >
              Find Address
            </v-btn>
          </v-flex>
          <v-flex
            v-if="addresses && addresses.addresses.length>0"
            xs8
            class="ml-3"
          >
            <v-select
              v-model="selectedAddress"
              flat
              :items="addresses.addresses"
              item-text="addressString"
              item-value="key"
              label="Select Street Address"
            />
          </v-flex>
        </v-layout>
        <!-- Main Number and Contact Name Fields -->
        <v-layout>
          <v-flex xs4>
            <v-text-field
              v-model="account.MainNumber"
              label="Main Number"
              :rules="mainnumberRules"
              :counter="15"
              required
            />
            <v-text-field
              v-model="account.ContactName"
              label="Contact Name"
              :rules="contactnameRules"
              :counter="60"
              required
            />
          </v-flex>
        </v-layout>
        <!-- RTP Capture Targets and Expiry Fields -->
        <v-layout>
          <v-flex xs4>
            <v-text-field
              v-model="account.RTPCaptureTargets"
              label="RTP Capture Targets"
              :rules="RTPCaptureTargets"
            />
          </v-flex>
          <v-flex xs4>
            <DatePicker
              v-model="account.RTPCaptureExpiry"
              label="RTP Capture Expiry"
              class="ml-8"
              clearable
            />
          </v-flex>
        </v-layout>
        <!-- Save and Cancel Buttons -->
        <v-layout class="mt-2">
          <v-btn
            primary
            color="primary"
            dark
            @click="save"
          >
            Save
          </v-btn>
          <v-btn
            text
            color="grey"
            @click="$router.push('/accounts')"
          >
            Cancel
          </v-btn>
        </v-layout>
      </v-form>
    </v-container>
    <!-- Delete Dialogue Component -->
    <delete-dialogue
      v-model="showAccountStateDialogue"
      :title="isAccountActive ? 'Deactivate Account' : 'Activate Account'"
      :confirm-label="isAccountActive ? 'Deactivate' : 'Activate'"
      :loading="togglingAccount"
      @delete="toggleAccountState"
    />
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment-timezone';
import { getaddress_url, getaddress_api_key, BTL_API_ACCOUNT_URL } from '@/config.js';
import permittedFunction from '@/permittedFunction.js';
import DeleteDialogue from '@/components/DeleteDialogue.vue';
import DatePicker from '@/components/DatePicker.vue';

const isValidJson = (v) => {
  try {
    JSON.parse(v);
    return true;
  } catch (e) {
    return false;
  }
};

export default {
  name: 'AccountEdit',
  components: { DatePicker, DeleteDialogue },
  data() {
    return {
      valid: false,
      addresses: null,
      pcLookup: '',
      selectedAddress: null,
      account: {
        name: '',
        State: false,
      },
      loaded: false,
      rules: {
        name: [(v) => !!v || 'Name is required', (v) => (v && v.length <= 60) || 'Name must not have more than 60 characters'],
        address1: [(v) => !!v || 'Street address 1 is required', (v) => (v && v.length <= 120) || 'Address must not have more than 120 characters'],
        city: [(v) => !!v || 'City is required', (v) => (v && v.length <= 60) || 'City must not have more than 60 characters'],
        postcode: [(v) => !!v || 'Post code is required', (v) => (v && v.length <= 10) || 'Post code must not have more than 10 characters'],
        country: [(v) => !!v || 'Country is required', (v) => (v && v.length <= 30) || 'Country must not have more than 30 characters'],
        mainNumber: [(v) => !!v || 'Main number is required', (v) => (v && v.length <= 15) || 'Main number must not have more than 15 characters'],
        contactName: [(v) => !!v || 'Contact name is required', (v) => (v && v.length <= 60) || 'Contact name must not have more than 60 characters'],
        address2: [(v) => (v && v.length <= 120) || 'Address must not have more than 120 characters'],
        RTPCaptureTargets: [(v) => (v.length === 0 || isValidJson(v)) || 'RTP Capture Targets must be valid JSON'],
      },
      archivingRestoring: false,
      showAccountStateDialogue: false,
      togglingAccount: false,
    };
  },
  computed: {
    isAccountActive() {
      return this?.account?.State === 'ACTIVE';
    },
  },
  watch: {
    selectedAddress() {
      const selected = this.addresses.addresses[this.selectedAddress];
      this.account = {
        ...this.account,
        Address1: selected.line_1,
        Address2: selected.line_2 || selected.locality,
        City: selected.town_or_city,
        Country: selected.country,
        PostCode: this.addresses.postcode,
      };
    },
    'account.RTPCaptureTargets': function (newVal, oldVal) {
      if (oldVal?.length === 0) {
        this.account.RTPCaptureExpiry = moment().add(14, 'days').toISOString();
      }
    },
  },
  mounted() {
    this.loadAccount();
  },
  methods: {
    isPermitted(endpoint, verb, resources) {
      return permittedFunction(endpoint, verb, resources);
    },
    findAddresses() {
      axios.get(`${getaddress_url}/${this.pcLookup}?expand=true&api-key=${getaddress_api_key}`)
        .then((response) => {
          this.addresses = response.data;
          this.addresses.addresses.forEach((a, i) => {
            a.addressString = `${a.formatted_address[0]}, ${a.formatted_address[3]}, ${a.formatted_address[4]}`;
            a.key = i;
          });
        })
        .catch(() => {
          this.$emit('snack', 'Invalid Postcode!');
          this.pcLookup = '';
          this.addresses = null;
        });
    },
    loadAccount() {
      axios.get(`${BTL_API_ACCOUNT_URL}/${this.$route.params.id}`)
        .then((response) => {
          this.account = response.data;
          if (!this.account.Address2) {
            this.account.Address2 = '';
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    save() {
      const requestBody = { ...this.account };
      requestBody.State = requestBody.State ? 'ACTIVE' : 'DISABLED';
      axios.put(`${BTL_API_ACCOUNT_URL}/${this.$route.params.id}`, requestBody)
        .then(() => {
          this.$emit('snack', 'Account successfully saved!');
          this.$router.push('/accounts');
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async archive() {
      this.archivingRestoring = true;
      try {
        await axios.put(`${BTL_API_ACCOUNT_URL}/${this.$route.params.id}`, { Archived: true });
        this.$emit('snack', 'Account Archived');
        this.loadAccount();
      } catch (err) {
        this.$emit('snack', 'Failed To Archive Account', true);
      }
      this.archivingRestoring = false;
    },
    async restore() {
      this.archivingRestoring = true;
      try {
        await axios.put(`${BTL_API_ACCOUNT_URL}/${this.$route.params.id}`, { Archived: false });
        this.$emit('snack', 'Account Restored');
        this.loadAccount();
      } catch (err) {
        this.$emit('snack', 'Failed To Restore Account', true);
      }
      this.archivingRestoring = false;
    },
    async toggleAccountState() {
      this.togglingAccount = true;
      try {
        await axios.put(`${BTL_API_ACCOUNT_URL}/${this.$route.params.id}`, { State: this.isAccountActive ? 'DISABLED' : 'ACTIVE' });
        this.$emit('snack', `Account ${this.isAccountActive ? 'Deactivated' : 'Activated'}`);
        this.loadAccount();
      } catch (err) {
        this.$emit('snack', `Failed To ${this.isAccountActive ? 'Deactivate' : 'Activate'}  Account`, true);
      }
      this.togglingAccount = false;
      this.showAccountStateDialogue = false;
    },
  },
};

// //     Consolidated form field validation rules into a single rules object for better readability and maintainability.
// Simplified the selectedAddress watcher logic to directly assign properties to this.account.
//     Used spread syntax to clone the this.account object in the save method to avoid modifying the original object.
//     Removed unnecessary conditional checks and simplified code where possible.
//     Ensured consistent use of const and let for variable declarations.
//     Added comments for improved code documentation.
</script>

<style scoped>
</style>
