const DialTrunk = {
  id: undefined,
  name: 'Route to Trunk',
  action: 'DialTrunk',
  layout: {
    y: undefined,
    x: undefined,
  },
  parameters: {
    trunkid: '',
  },
  conditions: {
    DIALSTATUS: {
    },
  },
  next: 99,
};

const DialTrunkWithNumber = {
  id: undefined,
  name: 'Dial Trunk with a number',
  action: 'DialTrunkWithNumber',
  layout: {
    y: undefined,
    x: undefined,
  },
  parameters: {
    trunkid: '',
    phonenumber: '',
  },
  conditions: {
    DIALSTATUS: {
    },
  },
  next: 99,
};

const DialNumber = {
  id: undefined,
  name: 'Dial number',
  action: 'DialNumber',
  layout: {
    y: undefined,
    x: undefined,
  },
  parameters: {
    phonenumber: null,
  },
  conditions: {
    DIALSTATUS: {
    },
  },
  next: 99,
};

const CheckSiteWorkinghours = {
  id: undefined,
  name: 'Check Workinghours',
  action: 'CheckSiteWorkinghours',
  layout: {
    y: undefined,
    x: undefined,
  },
  parameters: {
    siteid: null,
    workinghours: {},
    timezone: null,
    exceptions: {},
  },
  conditions: {
    workinghours: {
      True: 99,
      False: 99,
    },
  },
  next: 99,
};

const SiteDR = {
  id: undefined,
  name: 'Check DR Active',
  action: 'SiteDR',
  layout: {
    y: undefined,
    x: undefined,
  },
  parameters: {
    siteid: '',
  },
  conditions: {
    SiteDR: {
      True: 99,
      False: 99,
    },
  },
  next: 99,
};

const HangUp = {
  id: undefined,
  name: 'Hang up',
  action: 'HangUp',
  layout: {
    y: undefined,
    x: undefined,
  },
  parameters: {
    cause: null,
  },
  next: 99,
};

const CheckDCL = {
  id: undefined,
  name: 'DDI limiting',
  action: 'CheckDCL',
  parameters: {
    threshold: null,
  },
  conditions: {
    CheckDCL: {
      next: 99,
      overflow: 99,
    },
  },
  layout: {
    y: undefined,
    x: undefined,
  },
  next: 99,
};

const DynamicDialTrunk = {
  id: undefined,
  name: 'Dynamic Route To Trunk',
  action: 'DynamicDialTrunk',
  layout: {
    y: undefined,
    x: undefined,
  },
  parameters: {
    endpointId: undefined,
    endpointVersion: undefined,
    endpointUrl: undefined,
    endpointAuthType: undefined,
    endpointUserName: undefined,
    endpointPassword: undefined,
    endpointApiKey: undefined,
    endpointTimeout: undefined,
    ringTimeout: undefined,
    trunkid: undefined,
    sendRequestIfAnon: undefined,
  },
  conditions: {
    DIALSTATUS: {
    },
  },
  next: 99,
};

const ReceiveFax = {
  id: undefined,
  name: 'Receive Fax',
  action: 'ReceiveFax',
  layout: {
    y: undefined,
    x: undefined,
  },
  parameters: {
    email: '',
  },
  next: 99,
};

const SetSIPHeader = {
  id: undefined,
  name: 'Set SIP Header',
  action: 'SetSIPHeader',
  layout: {
    y: undefined,
    x: undefined,
  },
  parameters: {
    name: null,
    value: null,
  },
  next: 99,
};

export const types = [
  SiteDR,
  CheckSiteWorkinghours,
  DialNumber,
  DialTrunkWithNumber,
  DynamicDialTrunk,
  HangUp,
  CheckDCL,
  ReceiveFax,
  DialTrunk,
  SetSIPHeader,
];

export const typesForSelect = [
  {
    text: 'none',
    value: 'none',
  },
  ...types.map((type) => ({ text: type.name, value: type.action })),
];
